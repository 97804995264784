* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #ed5828;
  --lightgrey-color: #1d1d1d;
  --middlegrey-color: #181818;
  --darkgrey-color: #8d8c8c;
  --verylightgrey-color: #363636;
  --almostwhite-color: #d1d1d1;
  --almostblack-color: #111111;
  --huge-size: 6rem;
  --hugexs-size: 5rem;
  --bigger-size: 3rem;
  --bigmore-size: 2.5rem;
  --big-size: 1.3rem;
  --large-size: 1.1rem;
  --medium-size: 1rem;
  --small-size: 0.85rem;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: black;
  font-family: "Open Sans",sans-serif;
  font-size: var(--medium-size);
  color: black;
  text-decoration: none;
}

.small-text {
  font-size: var(--small-size);
  color: var(--darkgrey-color);
  text-decoration: none;
}

.middle-strong-text {
  font-size: var(--medium-size);
  color: black;
  font-weight: bold;
  text-decoration: none;
}

