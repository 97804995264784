.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.main-content {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}
