.main-content_contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 80%;
}

.main-content_map {
  width: 50%;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: black;
  margin-right: -50px;
}

.main-content_left {
  z-index: 2;
  width: 50%;
}

.main-content_right {
  z-index: 2;
  width: 45%;
}

.main-content_right h1 {
  color: var(--primary-color);
  font-size: var(--bigmore-size);
  font-weight: bold;
  text-decoration: none;
}

.main-content_contactdescription {
  margin: var(--big-size) 0;
}

.main-content_contactdescription a{
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.main-content_contactdescription a:hover{
  color: var(--primary-color);
  font-weight: bold;
  text-decoration: none;
}

.main-content_contactdescription p {
  color: var(--almostwhite-color);
  font-size: var(--medium-size);
  text-decoration: none;
  margin: var(--large-size) 0;
  width: 80%;
}

.main-content_info {
  border-radius: 10px;
  z-index: 10;
  position: absolute;
  background: rgba(0,0,0,.8);
  top: 50px;
  right: 1rem;
  width: 200px;
  padding: 20px;
  color: var(--almostwhite-color);
  opacity: 1;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: opacity .4s,-webkit-transform .9s;
  transition: opacity .4s,-webkit-transform .9s;
  -o-transition: opacity .4s,transform .9s;
  transition: opacity .4s,transform .9s;
  transition: opacity .4s,transform .9s,-webkit-transform .9s;
}

input {
  border: none;
  font-family: "Open Sans",sans-serif;
}

textarea {
  border: none;
  font-family: "Open Sans",sans-serif;
  height: 100px;
}

::-webkit-input-placeholder {
  font-family: "Open Sans",sans-serif;
}

button:focus {
  outline: none;
}

.highlight {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  cursor: pointer;
  transition: color .2s ease;
}

.highlight:hover {
  color: #fff;
  transition: color .2s ease;
}

.spacing {
  flex-grow: 1;
  height: 120px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.65)
}

.input-line:focus {
  outline: none;
  border-color: #fff;
  transition: all .2s ease;
}

.ghost-round {
  cursor: pointer;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.65);
  border-radius: 25px;
  color: rgba(255, 255, 255, 0.65);
  align-self: flex-end;
  font-size: 19px;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2.5em;
  margin-top: 20px;
  margin-bottom: 25px;
  transition: all .2s ease;
}

.ghost-round:hover {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.input-line {
  background: none;
  margin-bottom: 10px;
  line-height: 2.4em;
  color: #fff;
  font-family: roboto;
  font-weight: 300;
  letter-spacing: 0px;
  letter-spacing: 0.02rem;
  font-size: 19px;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.65);
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
}

.center {
  display: flex;
  justify-content: center;
}

.full-width {
  width: 100%;
}

.middle-width {
  width: 50%;
}

.input-fields {
  margin-top: 50px;
}

.menu {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 50px;
}

.bold-line {
  background: #e7e7e7;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  width: 100%;
  height: 360px;
  z-index: 1;
  opacity:0.1;
  background-size:cover;
}

@media screen and (max-width: 800px) {
  .main-content_map {
    display: none;
  }
  .main-content_info {
     display: none;
  }

  .main-content_right {
    z-index: 2;
    width: 100%;
  }
}

.maptransition-enter {
  opacity: 0;
}
.maptransition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}


