.main-content_work {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
  align-self: baseline;
  grid-gap: 30px;
  width: 80%;
}

.main-content_work h1 {
  color: var(--primary-color);
  font-size: var(--hugexs-size);
  font-weight: bold;
  text-decoration: none;
}
