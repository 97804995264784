.card {
    font-size: 16px;
    width: 300px;
    background-color: black;
    overflow: hidden;
    -webkit-transition: box-shadow 0.5s;
    transition: box-shadow 0.5s;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
}

.card a {
    color: inherit;
    text-decoration: none;
}

.card:hover {
    cursor: pointer;
}

.card__thumb {
    height: 245px;
    overflow: hidden;
    background-color: #000;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.card__thumb img {
    width: 300px;
    height: 245px;
    display: block;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: opacity 0.5s, transform 0.5s;
    transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
}

.card:hover .card__thumb {
    height: 130px;
}

.card:hover .card__thumb img {
    opacity: 0.6;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.card__body {
    position: relative;
    height: 115px;
    padding: 0 20px;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
}

.card:hover .card__body {
    height: 230px;
}

.card__category {
    position: absolute;
    top: -25px;
    left: 0;
    height: 25px;
    padding: 0 15px;
    background-color: var(--primary-color);
    color: #fff;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 25px;
}

.card__title {
    margin: 0;
    padding: 10px 0 10px 0;
    color: white;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
}

.card__subtitle {
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 19px;
    color: var(--primary-color);
}

.card__description {
    margin: 0;
    padding: 0;
    color: var(--darkgrey-color);
    line-height: 27px;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.card:hover .card__description {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.card__footer {
    margin: 0 20px 10px 20px;
    font-size: 13px;
}

.card__footer p {
  font-size: 13px;
  color: white;
}

.card__footer a {
  text-decoration: underline;
  color: var(--darkgrey-color);
}

.card__footer a:hover {
  color: var(--primary-color);
}

.cardtransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.cardtransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
