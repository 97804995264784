.main-menu_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 133px;
  padding: 0 30px;
  z-index: 10;
  background-color: black;
}

.main-menu_logo  {
  text-align: center;
  margin: 30px 0;
}

.main-menu_nav  {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-menu_nav a {
  text-align: center;
  display: block;
  font-size: var(--big-size);
  color: white;
  -webkit-transition: color .3s,background-color .3s;
  -o-transition: color .3s,background-color .3s;
  transition: color .3s,background-color .3s;
  text-decoration: none;
  margin-left: 40px;
}

.main-menu_nav a:hover {
  color: var(--primary-color);
}

.selected{
  color: var(--primary-color) !important;
}

.logoHighlight {
  font-size: var(--bigger-size);
  color: var(--primary-color);
}

.logoOverlight {
  font-size: var(--bigger-size);
  color: white;
}


@media screen and (max-width: 800px) {
  .main-menu_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 30px;
    height: 275px;
  }

  .main-menu_logo  {
    text-align:left;
    }

  .main-menu_nav  {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .main-menu_nav a {
    margin-right: 40px;
    margin-left: 0;
  }
}

