.main-content_about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
}

.main-content_me {
  display: flex;
  flex-direction: column;
}

.main-content_me h1 {
  color: var(--primary-color);
  font-size: var(--bigmore-size);
  font-weight: bold;
  text-decoration: none;
}

.main-content_specialized {
  margin: var(--big-size) 0;
}

.main-content_skillsdetails {
  margin: var(--big-size) 0;
	display: flex;
	flex-direction: column;
}

.chart {
	width: 80%;
	margin-bottom: 15px;
}

.chart span {
    font-size: 14px;
		color: var(--almostwhite-color);
    margin-bottom: 4px;
    display: block;
}

.chart footer {
    background-color: #373737;
    height: 2px;
}

.chart footer div {
    width: 0;
    height: 100%;
    background-color: var(--primary-color);
    -webkit-transition: width 1.3s ease-out;
    -o-transition: width 1.3s ease-out;
    transition: width 1.3s ease-out;
}


.main-content_specialized p {
  color: var(--almostwhite-color);
  font-size: var(--medium-size);
  text-decoration: none;
  margin: var(--large-size) 0;
  width: 80%;
}

h3 {
	margin: 10px 0;
  color: white;
}

h3 {
  color: white;
}

h6 {
	margin: 5px 0;
	text-transform: uppercase;
  color: var(--darkgrey-color);
}

p {
	font-size: 14px;
	line-height: 21px;
  color: var(--almostwhite-color);
}

.card-container {
	background-color: black;
	border-radius: 10px;
  border: 1px solid var(--primary-color);
	box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
	color: var(--almostwhite-color);
	padding-top: 30px;
	position: relative;
	width: 380px;
	max-width: 100%;
	text-align: center;
  justify-content: center;
}

.card-container .pro {
	color: black;
	background-color: white;
	border-radius: 5px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;
	position: absolute;
	top: 30px;
	left: 65px;
}

.description p{
	color: var(--darkgrey-color);
}

.highlight {
  color: white;
}

.card-container .round {
	border: 1px solid var(--primary-color);
	border-radius: 50%;
	padding: 7px;
}

.skills {
	background-color: var(--almostblack-color);
  border-radius: 10px;
	text-align: left;
	padding: 15px;
	margin-top: 30px;
}

.skills ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.skills ul li {
	border: 1px solid var(--verylightgrey-color);
	border-radius: 2px;
	display: inline-block;
	font-size: 12px;
	margin: 0 7px 7px 0;
	padding: 7px;
  color: white;
}

@media screen and (max-width: 800px) {
  .main-content_about {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.main-content_me {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.main-content-skills {
		display: flex;
		justify-content: center;
		text-align: center;
	}
}

.skillstransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.skillstransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}

.metransition-enter {
  opacity: 0;
}
.metransition-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}

@keyframes w95 {
    from { width: 0%; }
    to { width: 95%; }
}

@keyframes w75 {
    from { width: 0%; }
    to { width: 75%; }
}

@keyframes w20 {
    from { width: 0%; }
    to { width: 20%; }
}

@keyframes w50 {
    from { width: 0%; }
    to { width: 50%; }
}

@keyframes w85 {
    from { width: 0%; }
    to { width: 85%; }
}

